<template>
    <div class="card-body">
        <div class="card-body-title">Извещения</div>
        <div class="fileList" v-if="list.length">
            <div class="row flex">
                <div class="col-4">Название</div>
                <div class="col-3">Отправлено</div>
                <div class="col-3">Получено</div>
                <div class="col-2"></div>
            </div>
            <div :class="['row', 'flex', `row-${item.id}`]" v-for="item in list">
                <div class="col-4">{{item.title}}</div>
                <div class="col-3">
                    <input type="date" class="date_send" :value="item.date_send"/>
                    <div class="btn sm blue" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter() || $store.state.isClaimant() || $store.state.isDefendant()" @click="open(item.id,item.parent_id,'send')">Открыть файл</div>
                    <label>
                        <div class="btn sm yellow" v-if="$store.state.isAdmin() || $store.state.isImplementor()">Заменить файл</div>
                        <input type="file" class="file file_send" @change="changeFile(item.id,item.parent_id,'send')"/>
                    </label>
                </div>
                <div class="col-3">
                    <input type="date" class="date_receive" :value="item.date_receive"/>
                    <div class="btn sm blue" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()  || $store.state.isClaimant() || $store.state.isDefendant()" @click="open(item.id,item.parent_id,'confirm')">Открыть файл</div>
                    <label>
                        <div class="btn sm yellow" v-if="$store.state.isAdmin() || $store.state.isImplementor()" @click="change('confirm')">Заменить файл</div>
                        <input type="file" class="file file_confirm" @change="changeFile(item.id,item.parent_id,'confirm')"/>
                    </label>
                </div>
                <div class="control col-2">
                    <div class="btn red" v-if="$store.state.isAdmin()" @click="del(item.id,item.parent_id)">Удалить</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "notificationList",
    props:{
        list: Array,
    },
    methods: {
        changeFile(notification_id,claim_id,type) {
            this.loading = true;
            let valid = true;
            let date = false;
            let formData = new FormData();
            switch (type) {
                case 'send':
                    date = this.$el.querySelector(`.row-${notification_id} input.date_send`).value;
                    if (!date.length) {valid = false;}
                    formData.append('file',this.$el.querySelector(`.row-${notification_id} .file_send`).files[0]);
                    formData.append('date',date);
                    break;
                    case 'confirm':
                        date = this.$el.querySelector(`.row-${notification_id} input.date_receive`).value;
                        if (!date.length) {valid = false;}
                        formData.append('file',this.$el.querySelector(`.row-${notification_id} .file_confirm`).files[0]);
                        formData.append('date',date);
                        break;
            }
            if(valid) {
                this.$api.post(`claim/${claim_id}/notification/${notification_id}/${type}`,formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.$store.commit('showNotify',{title:'Сохранено',content:'Файл успешно загружен',type:'success'});
                        this.title = '';
                        this.number = '';
                        this.$emit('updated');
                    } else {
                        this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                    }
                })
            } else {
                this.$store.commit('showNotify',{title:'Ошибка',content:'Дата не может быть пуста',type:'danger'});
            }
        },
        open(notification_id,claim_id,type) {
            this.$api.get(`claim/${claim_id}/notification/${notification_id}/${type}`).then(res => {
                if (res.data.success) {
                    const linkSource = `data:${res.data.data.mime_type};base64,${res.data.data.base64}`;
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);
                    downloadLink.href = linkSource;
                    downloadLink.target = '_self';
                    downloadLink.download = `notification-${claim_id}-${notification_id}-${type}-${res.data.data.name}`;
                    downloadLink.click();
                    downloadLink.remove();
                    this.$emit('updated');
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        del(notification_id,claim_id) {
            this.$api.delete(`claim/${claim_id}/notification/${notification_id}/del`).then(res => {
                if (res.data.success) {
                    this.$emit('updated');
                    this.$store.commit('showNotify',{title:'Удалено',content:'Файл успешно удален',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
    .fileList {
        .row {
            align-items: center;
        }
        .control {
            .btn {margin: 0 2px;}
        }
        input {
            &.file {display: none;}
        }
    }
</style>